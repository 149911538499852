import lazyRetry from "@tdotcode/react-lazy-retry";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/Login/forgot-password";
import LoginPage from "../pages/Login/index";
import IframePage from "../pages/Iframe";
import Explore from "../pages/Explore";
import CreateAccount from "../pages/Onboarding/create-account";
import ComingSoonPage from "../pages/ComingSoon";
import Dashboard from "../pages/Dashboard";
import HomePage from "../pages/Home";
import SummerCohort from "../pages/SummerCohort";
import ContactPage from "../pages/Contact";
import ApplicationSuccessPage from "../pages/SummerCohort/applicationSuccess";

const ErrorPage = lazyRetry(() => import("../pages/error"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/about-us"
        element={<IframePage pageURL={"2747feaecfe94928b93d8753f591aaf9"} />}
      />
      <Route
        path="/terms-and-conditions"
        element={<IframePage pageURL={"f7ec797b20564a2c88f6ffce0f61409e"} />}
      />
      <Route
        path="/privacy-policy"
        element={<IframePage pageURL={"72dd48768286472b9ce8264aa97d7d26"} />}
      />
      <Route
        path="/cookies-policy"
        element={<IframePage pageURL={"27c4a08c23f9432f934f9fa3a9e6d26b"} />}
      />
    </Routes>
  );
};

export const pathHomepageUrls = {
  "UI / UX Design": "/ui-ux-design/1f5bff438ca04dbcbae6e8adbdb0c012",
  "Data Science with Python":
    "/data-science-with-python/2c5872b1052b4f75ae839c0ed2f9678f",
  "Management Consulting":
    "/management-consulting/e6c7a639df3740a3a6b048c79493cc43",
  "Artificial Intelligence for Healthcare":
    "/ai-for-healthcare/c1230bec0dd64526969a3f92e132f6f9",
  "Search Engine Optimisation (SEO)":
    "/search-engine-optimisation/7e0017f0cb5b40c996b5aa91b1f9384f",
};
export const pathUrls = {
  "UI / UX Design": "/ui-ux-design",
  "Data Science with Python": "/data-science-with-python",
  "Management Consulting": "/management-consulting",
  "Artificial Intelligence for Healthcare": "/ai-for-healthcare",
  "Search Engine Optimisation (SEO)": "/search-engine-optimisation",
};

export default AppRoutes;
