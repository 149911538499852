// src/pages/Home/index.tsx
import React from "react";
import { Element } from "react-scroll";
import RootPage from "../root";
import HeroSection from "./components/HeroSection";
import VideoSection from "./components/VideoSection";
import ExplainerSection from "./components/ExplainerSection";
import BannerCard from "./components/BannerCard";

const HomePage = () => {
  return (
    <RootPage header="home">
      <div className="flex flex-col items-center justify-center w-full max-w-full overflow-x-hidden my-8 sm:my-12 space-y-[4rem]">
        <HeroSection />
        <VideoSection />
        <ExplainerSection />
        <div className="hidden sm:block w-full">
          <BannerCard />
        </div>
      </div>
    </RootPage>
  );
};

export default HomePage;