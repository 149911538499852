import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

interface MiddlewareProps {
    children: JSX.Element;
}

const Middlewares = (props: MiddlewareProps) => {
    const [needLoginPage, setNeedsLoginPage] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("pathfinderAuthToken");
        // setNeedsLoginPage(token == null);
    }, []);

    return <>{needLoginPage ? <Navigate to="/login" /> : props.children}</>;
};

export default Middlewares;
