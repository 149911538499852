import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ProjectUploadModal } from "../ProjectUploadModal/index";
import axios from "axios";
import Authenticator from "../../services/authenticator";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface PageInfo {
  title: string;
  order: number;
  isProject: boolean;
  Path: {
    id: string;
    title: string;
    numOfPages: number;
    numOfProjects: number;
  };
  nextPageId: string | undefined;
  prevPageId: string | undefined;
}

const ProgressFooter = ({ pathName }: { pathName: string }) => {
  // All done, TODO: change the title and value of Circular progress to be dynamic
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageId, setPageId] = useState("");
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [dontLoadFooter, setDontLoadFooter] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);
  const handleModalToggle = () => {
    setIsModalOpen((prev) => !prev);
  };

  const location = useLocation();

  useEffect(() => {
    getCurrentPageInfo();
  }, [location]);

  const navigate = useNavigate();

  const getCurrentPageInfo = async () => {
    // console.log("Getting current page info");
    const url = window.location.href;
    // split the url on the last forward slash
    const splitUrl = url.split("/");
    const pageId = splitUrl[splitUrl.length - 1];
    setPageId(pageId);
    const lengthOfPageId = 36;
    const lengthOfPathIdWithoutHypens = 32;
    // console.log(pageId.length);
    // console.log(lengthOfPageId);
    if (
      pageId.length !== lengthOfPageId &&
      pageId.length !== lengthOfPathIdWithoutHypens
    ) {
      setDontLoadFooter(true);
      return;
    }
    // console.log(splitUrl);
    if (splitUrl.includes("path")) {
      setDontLoadFooter(true);
      return;
    }
    // console.log("Getting current page info2 ");
    const response = await axios.post(
      // "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/getPageInfoById",
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPageInfoById",
      {
        pageId: pageId,
      }
    );
    // console.log(response);
    if (response) {
      setCurrentPageInfo(response.data);
    }
  };

  const updatePathProgress = async () => {
    // console.log("Setting user progression");
    if (!currentPageInfo || !currentPageInfo.Path) {
      console.warn(
        "currentPageInfo or currentPageInfo.Path is null or undefined."
      );
      return;
    }

    const userId = parseInt(await Authenticator.getCurrentUserId(), 10); // Assuming userId is a string
    const pathId = parseInt(currentPageInfo.Path.id, 10); // Assuming pathId is a string
    const progress = Math.round(completionPercentage); // Round to nearest integer if it's a float

    const lastProgressed = new Date().toISOString(); // Assuming it's a timestamp, adjust if different

    console.log("Running setProgress");

    const response = axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/updatePathProgress",
      {
        userId: userId,
        pathId: pathId,
        pageId: pageId,
        progress: progress,
        lastProgressed: lastProgressed,
      }
    );
    console.log("setProgress: ", response);
  };

  useEffect(() => {
    getCurrentPageInfo();
  }, []);

  useEffect(() => {
    if (currentPageInfo) {
      const completion =
        currentPageInfo.order / currentPageInfo.Path.numOfPages + 0.07;
      const rounded = Math.round(completion * 93);
      setCompletionPercentage(rounded);
      // set User Progression
      updatePathProgress();
    } else {
      setCompletionPercentage(7);
      updatePathProgress();
    }
  }, [currentPageInfo]);

  return (
    <>
      {!dontLoadFooter && (
        <div>
          <footer className="xs:static bg-home-card-4 text-white w-full bottom-0 left-0 py-1 px-4 flex items-center justify-between">
            <div className="hidden md:block text-lg font-bold">
              {currentPageInfo !== null
                ? `${currentPageInfo.Path.title} Path`
                : ""}
            </div>
            {currentPageInfo && currentPageInfo.isProject && (
              <div className="w-[50%] flex md:justify-between space-x-4">
                <button
                  className="px-4 py-2 bg-white text-home-card-4 font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-home-card-4 focus:ring-opacity-50"
                  onClick={() => {
                    setLoadingPrev(true);
                    window.location.href = `/${pathName}/${currentPageInfo.prevPageId}`;
                    // navigate(`/${pathName}/${currentPageInfo.prevPageId}`);
                    setLoadingPrev(false);
                  }}
                  disabled={!currentPageInfo.prevPageId}
                >
                  {loadingPrev ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div>Previous Page</div>
                  )}
                </button>
                <button
                  className="px-4 py-2 bg-white text-home-card-4 font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-home-card-4 focus:ring-opacity-50"
                  onClick={handleModalToggle}
                >
                  Submit project
                </button>
                <button
                  className="px-4 py-2 bg-white text-home-card-4 font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-home-card-4 focus:ring-opacity-50"
                  onClick={() => {
                    setLoadingNext(true);
                    window.location.href = `/${pathName}/${currentPageInfo.nextPageId}`;
                    setLoadingNext(true);
                  }}
                  disabled={!currentPageInfo.nextPageId}
                >
                  {loadingNext ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div>Next Page</div>
                  )}
                </button>
              </div>
            )}
            {currentPageInfo &&
              !currentPageInfo.isProject &&
              currentPageInfo.order != 0 && (
                <div className="w-[50%] flex justify-between">
                  <button
                    className="px-4 py-2 bg-white text-home-card-4 font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-home-card-4 focus:ring-opacity-50 mr-2"
                    onClick={() => {
                      setLoadingPrev(true);
                      window.location.href = `/${pathName}/${currentPageInfo.prevPageId}`;
                      //   navigate(`/${pathName}/${currentPageInfo.prevPageId}`);
                      setLoadingPrev(false);
                    }}
                    disabled={!currentPageInfo.prevPageId}
                  >
                    {loadingPrev ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div>Previous Page</div>
                    )}
                  </button>
                  <button
                    className="px-4 py-2 bg-white text-home-card-4 font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-home-card-4 focus:ring-opacity-50"
                    onClick={() => {
                      setLoadingNext(true);
                      window.location.href = `/${pathName}/${currentPageInfo.nextPageId}`;
                      setLoadingNext(false);
                    }}
                    disabled={!currentPageInfo.nextPageId}
                  >
                    {loadingNext ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div>Next Page</div>
                    )}
                  </button>
                </div>
              )}

            <div className="flex items-center">
              <div className="hidden md:block">Amazing work, keep going! </div>
              <div className="ml-5 w-14">
                <CircularProgressbar
                  value={completionPercentage}
                  text={`${completionPercentage}%`}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: "#fff",
                    textSize: "26px",
                    trailColor: "rgba(255, 255, 255, 0.3)",
                    textColor: "#fff",
                  })}
                />
              </div>
            </div>
          </footer>
          <ProjectUploadModal
            isOpen={isModalOpen}
            onClose={handleModalToggle}
          />
        </div>
      )}
    </>
  );
};

export default ProgressFooter;
