import { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import headerLogo from "../../assets/headerLogo.png";
import Authenticator from "../../services/authenticator";
import axios from "axios";
import { Auth } from "aws-amplify";
import ReactGA from "react-ga4";
import PathFinderButton from "../common/PathFinderButton";
import { Link } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  active: string;
  onSignOut?: () => void;
  window?: () => Window;
}

// [Name on button, icon, placeAtStart, ]
interface NavItemsType {
  name: string;
  ga_name: string;
  icon?: JSX.Element;
  placeAtStart?: boolean;
  link?: string;
}
const navItems: NavItemsType[] = [
  {
    name: "Home",
    ga_name: "home",
    icon: <HomeIcon />,
    placeAtStart: true,
    link: "/",
  },
  {
    name: "Create an Account",
    ga_name: "create_account",
    icon: <AccountCircleIcon />,
    placeAtStart: true,
    link: "/login",
  },
];

export default function DrawerAppBar(props: Props) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedLogout, setClickedLogout] = useState(false);
  // const { user, setUser } = useUser();

  // useEffect(() => {
  //   const checkUserLoggedIn = async () => {
  //     const resp = await Authenticator.ifSignedIn();
  //     setUserLoggedIn(resp);
  //     if (resp) {
  //       const storedName = localStorage.getItem("userName");
  //       if (storedName) {
  //         setUser({ ...user, name: storedName });
  //       }
  //     }
  //     // console.log(
  //     //   "Getting the session storage outside:",
  //     //   sessionStorage.getItem("lastLoggedInUpdated")
  //     // );
  //     // if (resp && !sessionStorage.getItem("lastLoggedInUpdated")) {
  //     //   const now = new Date();
  //     //   const userId = await Authenticator.getCurrentUserId();

  //     //   const backendURL =
  //     //     "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/setUserLastLoggedIn";

  //     //   // Create the payload
  //     //   const payload = {
  //     //     userId: userId,
  //     //     lastLoggedIn: now.toISOString(), // Convert date/time to a string in the ISO 8601 format
  //     //   };

  //     //   // Make the POST request
  //     //   const response = await axios.post(backendURL, payload);
  //     //   // console.log(
  //     //   //   "Getting the session storage before:",
  //     //   //   sessionStorage.getItem("lastLoggedInUpdated")
  //     //   // );
  //     //   sessionStorage.setItem("lastLoggedInUpdated", "true");
  //     //   // console.log(
  //     //   //   "Getting the session storage after:",
  //     //   //   sessionStorage.getItem("lastLoggedInUpdated")
  //     //   // );
  //     // }
  //   };
  //   checkUserLoggedIn();
  //   if (clickedLogout) {
  //     setClickedLogout(false);
  //   }
  // }, [clickedLogout]);

  const navigate = useNavigate();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  async function signOut() {
    try {
      setLoading(true);
      await Authenticator.signOut();
      localStorage.removeItem("userName");
      localStorage.setItem("userEmail", "");
      // setUser({}); // Clear the user state
      setUserLoggedIn(false);
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Cant sign out: ", error);
    }
  }

  function accountButtonRender() {
    if (userLoggedIn) {
      return (
        <div className="flex items-center space-x-4 lg:order-2">
          <h5>Welcome back</h5>
          <PathFinderButton
            outlined={true}
            onClick={async () => {
              await signOut();
              setClickedLogout(true);
              props.onSignOut?.();
            }}
            loading={loading}
            disabled={false}
          >
            Log out
          </PathFinderButton>
          {/* <PathFinderButton
            type="button"
            onClick={() => {
              ReactGA.event({
                action: "dashboard",
                category: "header_links",
              });
              navigate("/dashboard");
            }}
            loading={false}
            disabled={false}
          >
            Dashboard
          </PathFinderButton> */}
        </div>
      );
    } else {
      return (
        <div className="flex items-center gap-4 lg:order-2">
          {/* <Link
            to="/contact"
            className="text-[#6B7280] hidden md:block underline mr-2 hover:text-gray-800"
          >
            Teach with Pathfinder
          </Link> */}
          <PathFinderButton
            onClick={() => navigate("/contact")}
            loading={false}
            disabled={false}
            outlined={true}
          >
            Teach with Pathfinder
          </PathFinderButton>
          <PathFinderButton
            onClick={() => {
              ReactGA.event({
                action: "create_account",
                category: "header_links",
              });
              window.open("https://calendar.app.google/fMKsGggUAz4V4KKk7", "_blank");
            }}
            loading={false}
            disabled={false}
          >
            Book a demo
          </PathFinderButton>
        </div>
      );
    }
  }

  return (
    <div className="w-full">
      <header className="w-full">
        <nav className="bg-white w-full mx-auto pt-5 px-4 2xl:px-0">
          <div className="flex flex-wrap justify-between items-center max-w-screen">
            {/* <a href="/" className="flex items-center">
              <span className="mr-3 text-4xl text-home-card-4 geoma-light">
                PATHFINDER
              </span>
            </a> */}
            <a href="/" className="flex items-center">
              <img
                src={headerLogo}
                className="mr-3 h-6 sm:h-9"
                alt="Pathfinder Logo"
              />
            </a>
            <div className="flex items-center lg:order-2">
              <div className="block xs:hidden sm:block">
                {accountButtonRender()}
              </div>
              {/* Kebab Menu */}
              <div className="sm:hidden">
                <button
                  type="button"
                  className="block text-gray-600 hover:text-gray-800 focus:text-gray-800 focus:outline-none"
                  onClick={() => {
                    const menu = document.getElementById("mobile-menu-2");
                    menu.classList.toggle("hidden");
                  }}
                >
                  <svg
                    className="h-10 w-10 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 6C11.45 6 11 6.45 11 7C11 7.55 11.45 8 12 8C12.55 8 13 7.55 13 7C13 6.45 12.55 6 12 6ZM12 10C11.45 10 11 10.45 11 11C11 11.55 11.45 12 12 12C12.55 12 13 11.55 13 11C13 10.45 12.55 10 12 10ZM12 14C11.45 14 11 14.45 11 15C11 15.55 11.45 16 12 16C12.55 16 13 15.55 13 15C13 14.45 12.55 14 12 14Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={
                "hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              }
              id="mobile-menu-2"
            >
              <ul className="md:hidden flex flex-col mt-4 font-bold lg:flex-row lg:space-x-4 xl:space-x-8 lg:mt-0">
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "about_us",
                        category: "header_links",
                      });
                      navigate("/about-us");
                    }}
                    className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                  >
                    About us
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "contact",
                        category: "header_links",
                      });
                      navigate("/contact");
                    }}
                    className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                  >
                    Contact
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "contact",
                        category: "header_links",
                      });
                      navigate("/contact");
                    }}
                    className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                  >
                    Teach with Pathfinder
                  </button>
                </li>
                {userLoggedIn ? (
                  <li>
                    <a
                      href="#"
                      className="block py-2 pr-4 pl-3 text-home-card-4 md:hidden"
                      onClick={async () => {
                        await signOut();
                        setClickedLogout(true);
                        props.onSignOut?.();
                      }}
                    >
                      Log out
                    </a>
                  </li>
                ) : (
                  <li>
                    <a
                      href="#"
                      className="block py-2 pr-4 pl-3 text-home-card-4 md:hidden"
                      onClick={async () => {
                        navigate("/login");
                      }}
                    >
                      Log In
                    </a>
                  </li>
                )}
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "demo",
                        category: "mobile-menu",
                      });
                      window.open(
                        "https://calendar.app.google/fMKsGggUAz4V4KKk7",
                        "_blank"
                      );
                    }}
                    className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                  >
                    Book a demo
                  </button>
                </li>
              </ul>
              <ul className="sm:hidden flex flex-col font-bold lg:flex-row lg:space-x-4 xl:space-x-8 lg:mt-0">
                {/* {!userLoggedIn ? (
                  <>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/login");
                        }}
                        className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                      >
                        Log In
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/get-started");
                        }}
                        className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                      >
                        Get Started
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <button
                      onClick={async () => {
                        await signOut();
                        setClickedLogout(true);
                        props.onSignOut?.();
                      }}
                      className="block py-2 pr-4 pl-3 text-home-card-4 hover:text-gray-800"
                    >
                      Log Out
                    </button>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
          <hr className="bg-home-card-4 border mt-5 border-home-card-4" />
        </nav>
      </header>
    </div>
  );
}
