import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "./TestimonialCard";
import NicoleDuslak from "../../../assets/nicole-duslak.jpeg";
import MercedesGrant from "../../../assets/mercedes-grant.jpeg";
import DeniseLever from "../../../assets/denise-lever.jpeg";
import LizetteValles from "../../../assets/lizette-valles.jpeg";

// Testimonial type definition
type Testimonial = {
  quote: string;
  name: string;
  role: string;
  schoolNetwork?: string;
  bold?: string[];
  imageSrc: string;
};

// Array of testimonials
const testimonials: Testimonial[] = [
  {
    quote:
      "Pathfinder has been an amazing addition to our classroom. It empowers learners to be self-directed in a digital environment with guardrails for credible sources and safety. Our students love it!",
    name: "Nicole Duslak",
    role: "Head of School at Create Conservatory",
    bold: ["Our students love it!", "empowers learners"],
    imageSrc: NicoleDuslak,
  },
  {
    quote:
      "Pathfinder has been an excellent tool for students who are looking to find student-friendly resources curated just for them. My students have enjoyed deepening their understanding on various topics as well as using AI to support their research.",
    name: "Mercedes Grant",
    role: "Founder of Path of Life Learning",
    schoolNetwork: "KaiPod",
    bold: ["student-friendly resources", "deepening their understanding"],
    imageSrc: MercedesGrant,
  },
  {
    quote:
      "The learners in my microschool love the program and are so excited to use this. They can't say enough about how much they enjoy this!",
    name: "Denise Lever",
    role: "Guide at Baker Creek Academy",
    schoolNetwork: "Prenda",
    bold: ["love the program", "are so excited"],
    imageSrc: DeniseLever,
  },
  {
    quote:
      "Pathfinder has changed the way resources are gleaned, objectives are met, and offers a strong starting point to delve deeper into topics of interest! Our microschool loves it!",
    name: "Lizette Valles",
    role: "Founder of Ellemercito Academy & Compass Educators",
    bold: ["strong starting point", "Our microschool loves it!"],
    imageSrc: LizetteValles,
  },
];

const TestimonialCarousel: React.FC = () => {
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.33,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    pauseOnFocus: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.33,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 898,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
    ],
  });

  return (
    <div className="bg-home-card-4 bg-opacity-20 2xl:pt-8 pb-10 w-[100vw]">
      <div className="w-[100%] sm:w-[90%] max-w-[1600px] mt-8 mx-auto px-8 h-full">
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex h-[20.5rem] sm:h-[17.5rem] md:h-[20rem] lg:h-[19rem] xl:h-[17rem] 2xl:h-[16] mb-4 md:px-1 xl:px-4 2xl:mb-4"
            >
              <TestimonialCard {...testimonial} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
