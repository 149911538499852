import { useParams } from "react-router-dom";
import RootNoFooterPage from "../rootNoFooter";
import { NotionPage } from "./notionPage";

const IframePage = ({
  pageURL,
  pathName,
}: {
  pageURL?: string;
  pathName?: string;
}) => {
  const { pageId } = useParams();
  const finalPageId = pageURL || pageId;

  return (
    <RootNoFooterPage header={"roadmap"} pathName={pathName}>
      <div className="flex items-center justify-center z-0">
        <NotionPage pageId={finalPageId} pathName={pathName} />
      </div>
    </RootNoFooterPage>
  );
};

export default IframePage;
