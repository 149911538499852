import React, { useEffect, useRef, useState } from "react";
import { Card, Avatar } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

type Testimonial = {
  quote: string;
  name: string;
  role: string;
  schoolNetwork?: string;
  bold?: string[];
  imageSrc: string;
};

const TestimonialCard: React.FC<Testimonial> = ({
  quote,
  name,
  role,
  schoolNetwork,
  bold = [],
  imageSrc,
}) => {
  const [fontSize, setFontSize] = useState(18); // Initial font size for the quote based on screen size
  const textContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 440) {
        if (quote.length > 200) {
          setFontSize(16);
        } else if (quote.length < 175) {
          setFontSize(19);
        } else {
          setFontSize(18);
        }
      } else if (window.innerWidth > 440 && window.innerWidth < 576) {
        if (quote.length > 200) {
          setFontSize(18);
        } else if (quote.length < 175) {
          setFontSize(22);
        } else {
          setFontSize(20);
        }
      } else if (window.innerWidth > 576 && window.innerWidth < 898) {
        if (quote.length > 200) {
          setFontSize(18);
        } else if (quote.length < 175) {
          setFontSize(22);
        } else {
          setFontSize(20);
        }
      } else if (window.innerWidth > 898 && window.innerWidth < 1024) {
        if (quote.length > 200) {
          setFontSize(17);
        } else if (quote.length < 175) {
          setFontSize(21);
        } else {
          setFontSize(19);
        }
      } else if (window.innerWidth > 1024 && window.innerWidth < 1280) {
        if (quote.length > 200) {
          setFontSize(18);
        } else if (quote.length < 175) {
          setFontSize(21);
        } else {
          setFontSize(19.5);
        }
      } else if (window.innerWidth > 1280 && window.innerWidth < 1536) {
        if (quote.length > 200) {
          setFontSize(18);
        } else if (quote.length < 175) {
          setFontSize(21);
        } else {
          setFontSize(19.5);
        }
      } else if (window.innerWidth > 1536 && window.innerWidth < 1638) {
        if (quote.length > 200) {
          setFontSize(16);
        } else if (quote.length < 175) {
          setFontSize(21);
        } else {
          setFontSize(17);
        }
      } else {
        if (quote.length > 200) {
          setFontSize(17);
        } else if (quote.length < 175) {
          setFontSize(21);
        } else {
          setFontSize(18);
        }
      }
    };

    handleResize(); // Call once to set initial state
    window.addEventListener("resize", handleResize); // Add event listener to adjust on resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup on component unmount
  }, [quote]); // Depend on quote length to adjust font size dynamically

  const processQuote = (quote: string, boldPhrases: string[]) => {
    let processedQuote: React.ReactNode[] = [quote]; // Explicitly declare as React.ReactNode[]
    boldPhrases.forEach((phrase) => {
      processedQuote = processedQuote.flatMap(
        (segment) =>
          typeof segment === "string"
            ? segment.split(phrase).reduce((acc, current, index, array) => {
                if (index < array.length - 1) {
                  return [
                    ...acc,
                    current,
                    <span
                      key={phrase + index}
                      className={`font-bold text-home-card-4`}
                      style={{ fontSize: `${fontSize + 0.5}px` }}
                    >
                      {phrase}
                    </span>,
                  ];
                }
                return [...acc, current];
              }, [] as React.ReactNode[])
            : [segment] // Handle non-string segments
      );
    });
    return processedQuote;
  };

  return (
    <div className="flex justify-center items-center space-x-4 px-[1px] h-full">
      <div className="hidden xl:flex border border-[#6B7280] bg-white h-full rounded-lg">
        <img
          ref={imageRef}
          src={imageSrc}
          alt={`Avatar of ${name}`}
          className="lg:w-[10rem] lg:h-[10rem] 2xl:w-[14rem] 2xl:h-[14rem] object-cover rounded-lg m-4"
        />
        <div
          ref={textContainerRef}
          className="flex flex-col justify-between text-left py-4 px-1 pb-6"
        >
          <h5
            className="font-normal text-black"
            style={{ fontSize: `${fontSize}px` }}
          >
            "{processQuote(quote, bold)}"
          </h5>
          <div className="flex flex-col">
            <h5 className="font-bold" style={{ fontSize: "16px" }}>
              {name}
            </h5>
            <div className="flex w-full gap-4">
              <span className="text-gray-600" style={{ fontSize: "14px" }}>
                {role}
              </span>
              {/* {schoolNetwork && (
                <div>
                  <span
                    className="text-home-card-4 font-bold bg-home-card-4 bg-opacity-10 px-1 2xl:px-2 rounded-md"
                    style={{ fontSize: "14px" }}
                  >
                    {schoolNetwork}
                  </span>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex xl:hidden border border-[#6B7280] bg-white max-h-full md:max-h-none md:h-full lg:max-h-full rounded-md">
        <div className="flex flex-col h-full p-5 space-y-5 md:space-y-2 md:justify-between">
          <h5
            className="font-normal text-black text-left"
            style={{ fontSize: `${fontSize}px` }}
          >
            "{processQuote(quote, bold)}"
          </h5>
          <div className="flex mt-2 justify-left items-center">
            <img
              src={imageSrc}
              alt={`Avatar of ${name}`}
              className="w-[5rem] h-[5rem] object-cover rounded-md"
            />
            <div className="flex flex-col text-left h-full justify-center ml-4">
              <h5
                className="text-base 2xl:text-lg font-bold"
                style={{ fontSize: "14px" }}
              >
                {name}
              </h5>
              <span className="text-gray-600" style={{ fontSize: "13px" }}>
                {role}
              </span>
              {schoolNetwork && (
                <div>
                  <span
                    className="text-home-card-4 font-bold bg-home-card-4 bg-opacity-10 px-1 2xl:px-2 rounded-md inline-block" // Added inline-block here
                    style={{ fontSize: "12px" }}
                  >
                    {schoolNetwork}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
