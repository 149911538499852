import React from "react";
import ExplainerCard from "./ExplainerCard";
import SocraticGuide from "../../../assets/socratic-guide.png";
import FindTopResources from "../../../assets/find-top-resources.png";
import TeacherDashboard from "../../../assets/teacher-dashboard.png";
import TestimonialCarousel from "./TestimonialCarousel";

const ExplainerSection = () => {
  return (
    <div className="flex flex-col space-y-8 sm:space-y-10 md:space-y-14 text-center items-center justify-center mt-[4rem] lg:my-12">
      <div className="flex flex-col px-4 md:px-0 md:w-4/5 max-w-[1120px] space-y-8">
        <h2 className="text-2xl sm:text-3xl lg:text-5xl font-bold">
          Pathfinder provides your learners with the{" "}
          <span className="underline">limitless</span> resources they need
        </h2>
        <h4 className="text-lg lg:text-xl">
          Let your learners explore projects or learning self-directedly.
          Pathfinder helps them get unstuck, learn from the best resources and
          apply what they've learnt into their work.
        </h4>
      </div>
      <TestimonialCarousel />
      <div className="flex items-center justify-center max-w-[1260px] w-[90%] flex-col space-y-6 md:space-y-12">
        <ExplainerCard
          title="Patty is a Socratic guide"
          description={[
            "Asks questions to help learners get unstuck",
            "Helps the learner choose their next step",
            "Can provide options if they're really not sure",
            "Makes sure the project is appropriate for the classroom environment",
          ]}
          image={SocraticGuide}
          imageAlt="Patty is a Socratic guide"
        />
        <ExplainerCard
          title="Patty finds the best resources for each learner to learn from"
          description={[
            "Makes sure they're safe for learners",
            "Resources are specific to the context of the learner's project",
            "Patty has gone through the resources too to answer any questions",
          ]}
          image={FindTopResources}
          imageAlt="Patty Finds the top resources on the internet"
          reverse
        />
        <ExplainerCard
          title="Patty keeps you up to date on your learners"
          description={[
            "Get a live overview of how each of your learners is progressing",
            "See a summary of each learner's progress",
            "Get notified when learners are stuck or need help",
          ]}
          image={TeacherDashboard}
          imageAlt="Patty notifies you when learners need help and keeps you up to date on their progress"
        />
      </div>
    </div>
  );
};

export default ExplainerSection;
