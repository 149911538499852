import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Alert, FileInput } from "flowbite-react";
import JSZip from "jszip";
import upload from "../../assets/upload.png";
import axios from "axios";
import Authenticator from "../../services/authenticator";
import { DashboardProjectInfo } from "../../pages/Dashboard";
import Lottie from "lottie-react";
import project_upload_success from "../../assets/lottie/project_upload_success.json";

interface ProjectUploadModalProps {
    isOpen: boolean;
    onClose: () => void;

    setProjects?: React.Dispatch<React.SetStateAction<DashboardProjectInfo[]>>;
}

export const ProjectUploadModal: React.FC<ProjectUploadModalProps> = ({ isOpen, onClose, setProjects }) => {
    const [titleText, setTitleText] = useState("");
    const [reflectionText, setReflectionText] = useState("");
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [fileInputArray, setFileInputArray] = useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileSizeWarning, setFileSizeWarning] = useState(false);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [uploadStatus, setUploadStatus] = useState("");
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

    const [timeRemaining, setTimeRemaining] = useState(100);
    const radius = 10;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (timeRemaining / 100) * circumference;

    const handleSubmit = async () => {
        if (
            titleText.length === 0 ||
            titleText.length > 100 ||
            fileInputArray.length === 0 ||
            !isCheckboxChecked ||
            reflectionText.length === 0 ||
            reflectionText.length > 500 ||
            reflectionText.length > 500 ||
            titleText.length === 0
        ) {
            alert(
                "Please upload a file, add a title, and ensure your reflection text doesn't exceed the 500 characters limit before submitting."
            );
            return;
        }

        if (!isCheckboxChecked && !showWarning) {
            setShowWarning(true);
            return;
        }
        try {
            setUploadStatus("Compressing...");

            // Create a new zip instance
            const filename = titleText + ".zip";
            const userId = await Authenticator.getCurrentUserId();

            const zip = new JSZip();
            // console.log("Uploading a project with the following files:");
            // console.log(fileInputArray);

            // let totalSizeBeforeZipping = 0;
            // fileInputArray.forEach((file) => {
            //     totalSizeBeforeZipping += file.size;
            // });
            // console.log("Total size before zipping: ", totalSizeBeforeZipping);

            // Add the files to the zip
            fileInputArray.forEach((file) => {
                zip.file(file.name, file);
            });

            // Generate a Blob for the zipped content
            const zippedBlob = await zip.generateAsync({ type: "base64" });
            // console.log("Size after zipping: ", (zippedBlob.length * 3) / 4);

            setUploadStatus("Uploading...");

            const response = await axios.post(
                "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/uploadUserProject",
                {
                    userId: userId,
                    title: titleText,
                    description: reflectionText,
                    filename: filename,
                    file: zippedBlob,
                },
                {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                        if (percentCompleted >= 100) {
                            setUploadStatus("Finalising...");
                        }
                        // setUploadStatus(`Uploading... ${percentCompleted}%`);
                    },
                }
            );
            // console.log("File uploaded", response);
            setShowSuccessAnimation(true);
            // console.log("Set show success animation to:", showSuccessAnimation);

            // console.log("File uploaded", response);
            if (setProjects !== undefined) {
                // console.log("Setting projects");
                setProjects((prev) => [
                    ...prev,
                    {
                        title: titleText,
                        description: reflectionText,
                        fileStorageLink: response.data.fileStorageLink ? response.data.fileStorageLink : "",
                    },
                ]);
            }
        } finally {
        }
    };

    // Use useEffect to react to changes in showSuccessAnimation and uploadProgress state
    useEffect(() => {
        if (showSuccessAnimation) {
            const timeoutId = setTimeout(() => {
                setShowSuccessAnimation(false);
                reset();
            }, 8000);
            // Cleanup function to clear the timeout when the component unmounts or before next effect runs
            return () => clearTimeout(timeoutId);
        }
    }, [showSuccessAnimation]);

    const reset = () => {
        onClose();
        setTitleText("");
        setReflectionText("");
        setIsCheckboxChecked(false);
        setFileInputArray([]);
        setShowWarning(false);
        setUploadProgress(0);
        setTotalFileSize(0);
        setUploadStatus("");
        setTimeRemaining(100);
    };

    useEffect(() => {
        if (showSuccessAnimation) {
            const countdown = setInterval(() => {
                setTimeRemaining((prevPercent) => {
                    if (prevPercent > 0) {
                        return prevPercent - (100/7); // Decrease by (100/7)% every second
                    } else {
                        clearInterval(countdown);
                        return 0;
                    }
                });
            }, 1000); // Update every second
            return () => clearInterval(countdown); // Clear interval on component unmount
        }
    }, [showSuccessAnimation]);

    if (!isOpen) return null;
    return createPortal(
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={onClose}></div>

                <div className="relative w-full max-w-2xl mx-4 md:mx-auto bg-white shadow-md rounded-md">
                    <button
                        className="absolute top-3 right-3 text-gray-700 hover:text-gray-800 focus:outline-none"
                        onClick={onClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>

                    <div className="p-6">
                        <textarea
                            className="mt-4 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:home-card-4 text-xl font-semibold resize-none overflow-hidden"
                            rows={1}
                            maxLength={100}
                            placeholder="Project title"
                            value={titleText}
                            onChange={(e) => setTitleText(e.target.value)}
                        ></textarea>
                        <div className="text-right text-sm text-gray-500 mb-2">{titleText.length}/100</div>
                        <p className="text-home-card-4 mb-6">
                            Congratulations on finishing your project! You’ve just made a huge step forward and should
                            be super proud of yourself 🚀
                        </p>
                        <h3 className="text-home-card-4 text-2xl font-medium mb-4">Upload your project here!</h3>
                        <div id="fileUpload">
                            <FileInput
                                id="file"
                                multiple={true}
                                onChange={(e) => {
                                    const files = Array.from(e.target.files);
                                    const totalSize = files.reduce((total, file) => total + file.size, 0);
                                    if (totalSize > 20 * 1024 * 1024) {
                                        // 20 MB
                                        setFileSizeWarning(true);
                                    } else {
                                        setFileSizeWarning(false);
                                        setFileInputArray(files);
                                    }
                                    setTotalFileSize(totalSize);
                                }}
                            />
                            <div className="text-right text-sm text-gray-500 pt-2">
                                Total size: {(totalFileSize / (1024 * 1024)).toFixed(2)} MB / 20 MB
                            </div>
                        </div>
                        <h4 className="text-home-card-4 text-lg font-medium mt-2 mb-2">Your Reflections:</h4>
                        <p className="text-home-card-4 mb-4">
                            A great way to consolidate what you’ve just been through and the lessons that you’ve learned
                            is by writing down some reflections. This will be super helpful for you when talking about
                            this project in the future! Take 5 minutes and add these below:
                        </p>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500"
                            rows={6}
                            maxLength={500}
                            placeholder="Your reflections"
                            value={reflectionText}
                            onChange={(e) => setReflectionText(e.target.value)}
                        ></textarea>
                        <div className="text-right text-sm text-gray-500">{reflectionText.length}/500</div>
                        <div className="flex items-center mt-4">
                            <input
                                type="checkbox"
                                className="w-8 h-8 mr-4 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:home-card-4"
                                checked={isCheckboxChecked}
                                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                            />
                            <label className="text-sm text-gray-700">
                                I am happy for Pathfinder to share this project with potential employers! (For more
                                information see our{" "}
                                <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-purple-600"
                                >
                                    Privacy Policy
                                </a>{" "}
                                and{" "}
                                <a
                                    href="/terms-and-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-purple-600"
                                >
                                    Terms and Conditions
                                </a>
                                ).
                            </label>
                        </div>
                        {showWarning && (
                            <Alert color="warning" rounded={true} className="mt-4">
                                <span>
                                    Just wanted to check if you want to share this project with potential employers? If
                                    so, make sure to tick the checkbox above but if not, click submit again!
                                </span>
                            </Alert>
                        )}
                        {uploadStatus !== "" && (
                            <div>
                                <div className="flex justify-between mb-1 mt-3">
                                    <span className="text-base font-medium text-home-card-4 dark:text-white">
                                        {uploadStatus}
                                    </span>
                                    <span className="text-sm font-medium text-home-card-4 dark:text-white">
                                        {uploadProgress}%
                                    </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                    <div
                                        className="bg-home-card-4 h-2.5 rounded-full"
                                        style={{ width: `${uploadProgress}%` }}
                                    ></div>
                                </div>
                            </div>
                        )}

                        {fileSizeWarning && (
                            <Alert color="warning" rounded={true} className="mt-4">
                                <span>
                                    The total size of the files exceeds the 20 MB limit. Please select smaller files.
                                </span>
                            </Alert>
                        )}

                        <button
                            className="flex items-center mt-6 px-4 py-2 ml-auto bg-home-card-4 text-white font-semibold rounded-md shadow-md hover:bg-home-card-1 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50"
                            onClick={handleSubmit}
                            disabled={
                                titleText.length === 0 ||
                                titleText.length > 100 ||
                                fileInputArray.length === 0 ||
                                !isCheckboxChecked ||
                                reflectionText.length === 0 ||
                                reflectionText.length > 500 ||
                                fileSizeWarning
                            }
                        >
                            Submit project
                            <img src={upload} alt="upload" className="w-5 h-5 ml-2" />
                        </button>
                        {showSuccessAnimation && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zIndex: 1000,
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    opacity: showSuccessAnimation ? 1 : 0,
                                    transition: "opacity 1s ease-in-out",
                                }}
                            >
                                {/* Congratulations text */}
                                <div className="flex justify-between items-center">
                                    <h3 className="text-green-400 text-xl font-bold mb-2">Congrats - Your project has been saved!</h3>
                                    <div className="ml-4">
                                        <div
                                            x-data="scrollProgress"
                                            className="inline-flex items-center justify-center overflow-hidden rounded-full bottom-5 left-5"
                                        >
                                            <svg className="w-10 h-10">
                                                <circle
                                                    className="text-gray-300"
                                                    stroke-width="5"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r={radius}
                                                    cx="20"
                                                    cy="20"
                                                />
                                                <circle
                                                    className="text-purple-600"
                                                    stroke-width="5"
                                                    strokeDasharray={circumference}
                                                    strokeDashoffset={strokeDashoffset}
                                                    stroke-linecap="round"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r={radius}
                                                    cx="20"
                                                    cy="20"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <Lottie animationData={project_upload_success} loop={true} autoplay={true} /> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById("modal-root")!
    );
};
