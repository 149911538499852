import React from "react";
import { Link } from "react-router-dom";
import RootPage from "./root";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../components/common/ErrorComponent";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <RootPage header="error">
      <ErrorComponent />
    </RootPage>
  );
};

export default ErrorPage;
