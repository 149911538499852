import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-[10%]">
      <h1 className="xs:text-5xl text-6xl md:text-8xl font-bold mb-6 flex items-center text-center">
        Whoops, it seems you're lost
      </h1>
      <button
        className="px-8 py-4 my-[4%] bg-home-card-4 text-white rounded-lg text-lg font-semibold"
        onClick={() => navigate("/")}
      >
        lets take you home
      </button>
    </div>
  );
};

export default ErrorComponent;
