import React from "react";
import PathFinderButton from "../../../components/common/PathFinderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import BannerImage from "../../../assets/banner.png";

const BannerCard = () => {
  return (
    <div
      className="flex w-full justify-between p-8 items-center rounded-md shadow-sm bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${BannerImage})` }} // Apply the background image
    >
      <h2 className="text-lg  md:text-2xl font-semibold">
        Want to see Pathfinder in action?
      </h2>
      <PathFinderButton
        loading={false}
        disabled={false}
        hover={true}
        onClick={() => {
          ReactGA.event({
            action: "demo",
            category: "banner_card",
          });
          window.open("https://calendar.app.google/fMKsGggUAz4V4KKk7", "_blank");
        }}
      >
        <div className="flex space-x-3 items-center justify-center">
          <text className="md:text-xl font-bold">Book a demo</text>
          <FontAwesomeIcon icon={faArrowRight} color="white" size="xl" />{" "}
        </div>
      </PathFinderButton>
    </div>
  );
};

export default BannerCard;
