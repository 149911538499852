import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";

interface RootPageProps {
  header: string;
  children: JSX.Element;
}

const RootPage = (props: RootPageProps) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="max-w-[1400px] w-full mx-auto">
        <Header active={props.header} />
        <div className="w-full 2xl:px-4">{props.children}</div>
      </div>
      <div className="bg-home-card-4 w-full">
        <div className="max-w-[1400px] w-full mx-auto">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RootPage;
