// Org Repo Frontend/frontend/src/pages/Home/cards/VideoSection.tsx
import React from "react";
import PathFinderButton from "../../../components/common/PathFinderButton";
import ReactPlayer from "react-player";

const VideoSection: React.FC = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div
        className={`md:w-[82.5%] w-[95%] rounded-lg h-auto lg:mb-[4rem]`}
        style={{ boxShadow: "0px 0px 80px -12px rgb(140, 59, 149, 0.8)" }}
      >
        <div className="relative pt-[62%]">
          {/* This padding-top gives a 16:9 aspect ratio */}
          <ReactPlayer
            url="https://youtu.be/o7U2clEExRM"
            controls={true}
            className="absolute top-0 left-0 rounded-lg"
            width="100%"
            height="100%" // Ensure the player takes the full height of its container
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
