import React from "react";

interface ExplainerCardProps {
  title: string;
  description: string[];
  image: string;
  imageAlt: string;
  reverse?: boolean;
}

const ExplainerCard: React.FC<ExplainerCardProps> = ({
  title,
  description,
  image,
  imageAlt,
  reverse = false,
}) => {
  // Tailwind classes for flex direction based on screen size and reverse prop
  const itemClass = `flex ${
    reverse ? "md:flex-row-reverse" : "md:flex-row"
  } flex-col-reverse`;

  return (
    <div
      className={`flex ${itemClass} justify-start md:justify-between md:mb-8  md:gap-12 gap-4`}
    >
      <div className="flex justify-start flex-col w-full md:w-1/2 space-y-4 p-4 text-left">
        <h2 className="font-bold mb-4">{title}</h2>
        {description.map((desc, index) => (
          <div key={index} className="flex items-start space-x-3">
            <h5 className="text-[#6B7280]">•</h5>
            <h5 className="text-[#6B7280]">{desc}</h5>
          </div>
        ))}
      </div>
      <div className="flex justify-start md:justify-center flex-col w-full md:w-1/2 p-4">
        <img src={image} alt={imageAlt} className="w-full" />
      </div>
    </div>
  );
};

export default ExplainerCard;
